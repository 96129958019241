<template>
    <div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Timesheet Management</p>
                <p class="text-title mb-0">You do not have permission to view all timesheet</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <p class="text-h6 mb-2">Timesheet Report</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-text-field dense hide-details clearable label="Project Name" v-model="filters.project"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-dialog persistent ref="dialog" width="320px" :return-value.sync="filters.report_date" v-model="reportDateModal">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field readonly dense hide-details label="Report Month" v-bind="attrs" v-on="on" v-model="filters.report_date"></v-text-field>
                                    </template>
                                    <v-date-picker scrollable type="month" min="2021-09" v-model="filters.report_date">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="reportDateModal = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(filters.report_date)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" order="last">
                                <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click="search">
                                    <v-icon small left>mdi-magnify</v-icon> View
                                </v-btn>
                                <v-btn small class="mt-2 elevation-0" :ripple="false" @click='reset'>
                                    <v-icon small left>mdi-undo</v-icon> Reset
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row class="mt-6">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
                        <template v-slot:item='{ item, index }'>
                            <tr>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.project"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.start_date"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.end_date"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.man_day"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.cpf_rate"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <v-menu bottom offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item :to="getViewUrl(item.id)" target="_blank">
                                                <v-list-item-title>View Report</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row v-if="pagination.total_pages > 1">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-pagination circle :length="pagination.num_pages" :total-visible="7" v-model="filters.page"></v-pagination>
                </v-col>
            </v-row>
        </template>
    </div>
</template>


<script>

import {makeDate} from '@/utils/dates'

export default {
    name: 'CampaignReportList',
    data() {
        return {
            loading: true,
            reportDateModal: null,
            headers: [],
            items: [],
            pagination: {},
            permissions: {
                can_list: false
            },
            filters: {
                project: '',
                report_date: makeDate(new Date().getFullYear(), new Date().getMonth() + 1, 1).substring(0,7),
                page: 1
            }
        }
    },
    computed: {
        createDialog() {
            return this.$refs.createDialog
        }
    },
    mounted: function() {
        this.get()
    },
    methods: {
        get: function() {
            this.loading = true
            const filters = { project: this.filters.project, report_date: this.filters.report_date + "-01" }
            this.$store.dispatch('epanel/report/getReportTimesheet', filters).then((response) => {
                this.items = response.data.items
                this.headers = response.data.headers
                this.pagination = response.data.pagination
                this.permissions = response.data.permissions
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        search: function() {
            this.filters.page = 1
            this.get()
        },
        reset: function() {
            this.filters = { project: '', report_date: makeDate(new Date().getFullYear(), new Date().getMonth() + 1, 1).substring(0,7), page: 1 }
        },
        getViewUrl: function(id) {
            return 'timesheet/' + id + '/info'
		},

    }
}

</script>