var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading && !_vm.permissions.can_list)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('p',{staticClass:"text-h5 mb-4"},[_vm._v("Timesheet Management")]),_c('p',{staticClass:"text-title mb-0"},[_vm._v("You do not have permission to view all timesheet")])])],1):_vm._e(),(_vm.permissions.can_list)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-sheet',{staticClass:"pa-0",attrs:{"color":"white","elevation":"0"}},[_c('p',{staticClass:"text-h6 mb-2"},[_vm._v("Timesheet Report")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"3","lg":"3","xl":"3"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","clearable":"","label":"Project Name"},model:{value:(_vm.filters.project),callback:function ($$v) {_vm.$set(_vm.filters, "project", $$v)},expression:"filters.project"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"3","lg":"3","xl":"3"}},[_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"320px","return-value":_vm.filters.report_date},on:{"update:returnValue":function($event){return _vm.$set(_vm.filters, "report_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.filters, "report_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","dense":"","hide-details":"","label":"Report Month"},model:{value:(_vm.filters.report_date),callback:function ($$v) {_vm.$set(_vm.filters, "report_date", $$v)},expression:"filters.report_date"}},'v-text-field',attrs,false),on))]}}],null,false,210891397),model:{value:(_vm.reportDateModal),callback:function ($$v) {_vm.reportDateModal=$$v},expression:"reportDateModal"}},[_c('v-date-picker',{attrs:{"scrollable":"","type":"month","min":"2021-09"},model:{value:(_vm.filters.report_date),callback:function ($$v) {_vm.$set(_vm.filters, "report_date", $$v)},expression:"filters.report_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.reportDateModal = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.filters.report_date)}}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"6","xl":"6","order":"last"}},[_c('v-btn',{staticClass:"mr-2 elevation-0",attrs:{"small":"","color":"primary","ripple":false},on:{"click":_vm.search}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-magnify")]),_vm._v(" View ")],1),_c('v-btn',{staticClass:"mt-2 elevation-0",attrs:{"small":"","ripple":false},on:{"click":_vm.reset}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-undo")]),_vm._v(" Reset ")],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","loading-text":"Loading... Please wait...","no-data-text":"No data available yet","loading":_vm.loading,"items-per-page":50,"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(index + 1)}}),_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(item.project)}}),_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(item.start_date)}}),_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(item.end_date)}}),_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(item.man_day)}}),_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(item.cpf_rate)}}),_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center"},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":_vm.getViewUrl(item.id),"target":"_blank"}},[_c('v-list-item-title',[_vm._v("View Report")])],1)],1)],1)],1)])]}}],null,false,3972070058)})],1)],1),(_vm.pagination.total_pages > 1)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-pagination',{attrs:{"circle":"","length":_vm.pagination.num_pages,"total-visible":7},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1)],1):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }